<template>
  <ValidationObserver @submit="addExtraWorkingUnit">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="showTypeModal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>{{ headerTitle }}</span>
      </template>
      <template v-slot>
        <div>
          <div class="flex flex-col">
            <InputBox
              v-model.trim="extraWorkingUnitObject.title"
              type="text"
              name="UNIT"
              title="UNIT"
              label="Title"
              placeholder="Write"
              :class="$style.widthItems"
              class="flex-1 w-full"
              rules="required"
            />
            <InputBox
              v-model="extraWorkingUnitObject.description"
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
              :class="$style.widthItems"
              class="flex-1 w-full"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <span v-if="isModalLoading">
              <Loader />
            </span>
            <span v-else>
              <span v-if="!updateTypeButton">Save</span>
              <span v-else>Update</span>
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import { objectDeepCopy } from '@utils/generalUtil'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  name: 'SalaryExtraWorkingUnit',
  components: {
    UiModalContainer,
    InputBox,
    ValidationObserver,
    UIButton,
    Loader,
  },
  props: {
    editableObject: { type: Object, default: () => null },
  },
  emits: ['update'],
  data() {
    return {
      salary: 'salary',
      extraWorkingUnitObject: { type: '', description: '' },
      updateTypeButton: false,
      edit: null,
    }
  },

  computed: {
    headerTitle() {
      return !updateTypeButton ? 'Add Extra Working Unit' : 'Edit Extra Working Unit'
    },
    ...mapState({
      showTypeModal: (state) => state.salary.showModal,
      isModalLoading: (state) => state.salary.isModalLoading,
    }),
  },
  created() {
    if (this.editableObject) {
      this.extraWorkingUnitObject = objectDeepCopy(this.editableObject)
      this.updateTypeButton = true
      return
    }
  },

  methods: {
    /**
     * Reset Form
     * @description Resetting fee type name and description to empty value
     */
    resetForm() {
      this.extraWorkingUnitObject.title = ''
      this.extraWorkingUnitObject.description = ''
    },

    /**
     * Add Salary Extra Working Unit
     * @param {boolean} invalid
     * @returns {void}
     * @description Method description
     * -  Edit existing fee type
     * -  Show toast regarding to action we perform eg:Create or Edit
     * -  Setting right bar data
     */
    async addExtraWorkingUnit() {
      let res, err

      if (this.updateTypeButton) {
        ;[res, err] = await this.updateExtraWorkingUnit(this.extraWorkingUnitObject)
      } else {
        ;[res, err] = await this.createExtraWorkingUnit(this.extraWorkingUnitObject)
      }
      if (!err) {
        this.handleClick('close')
        this.$emit('update')
      }
    },

    /**
     * Handle click
     * @param {string} eventName
     * @returns {void}
     * @description Calling setShowModal and resetForm method on the basis of event name
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.showModal()
          break
        case 'close':
          this.hideModal()
          this.resetForm()
          this.updateTypeButton = false
          break
      }
    },

    ...mapActions('salary', [
      'showModal',
      'hideModal',
      'createExtraWorkingUnit',
      'updateExtraWorkingUnit',
    ]),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
